<template>
	<div>
		<DataTable :value="$store.state.planning.detail" :scrollable="true" :scrollHeight="heigt_sc+'px'"  v-model:selection="selectedCustomers" 
			:filters="filters" class="p-datatable-sm" :paginator="false"
			editMode="cell" dataKey="id"  @cell-edit-init="Init" @cell-edit-complete="Save" 
			v-model:editingRows="editingRows" v-model:expandedRows="expandedRows"
		>		
			<Column :header="$t('N°')" bodyStyle="width: 30px; background-color: #CECECE; text-align: center;" headerStyle="width: 30px; background-color: #CECECE; text-align: center;">
				<template #body="slotProps">
					{{slotProps.index+1}}
				</template>
				<template #editor="slotProps">
					{{slotProps.index+1}}
				</template>
			</Column>
			<Column :expander="true" headerStyle="width: 30px" />
			<Column field="status" :header="$t('Status')" headerStyle="width: 130px">
				<template #body="{data}">
					{{ data['status_'+i18n.locale()] }}
				</template>
				<template #editor="{ data }">
					{{ data['status_'+i18n.locale()] }}
				</template>
			</Column>
			<Column field="questionnaire" :header="$t('Criteria')" bodyStyle="width: 60px; background-color: #CECECE; text-align: center;" headerStyle="width: 60px; background-color: #CECECE; text-align: center;">
				<template #body="{data}">
					<strong>{{data.compliance+'.'+data.ord}}</strong><br>
				</template>
				<template #editor="{data}">
					<strong>{{data.compliance+'.'+data.ord}}</strong><br>
				</template>
			</Column>		
			<Column field="description" :header="$t('Action description')" headerStyle="width: 300px">
				<template #body="{data}">
					{{ data.description }}
				</template>
				<template #editor="{data}">
					{{ data.description }}
				</template>
			</Column>
			<Column field="date_start" :header="$t('Date')+' '+$t('start')+' - '+$t('end')" headerStyle="width: 250px">
				<template #body="{data}">
					{{ data['date_start_'+i18n.locale()] }} - {{ data['date_end_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.edit"/>
				</template>
				<template #editor="{ data }">
					<InputText v-model="data.date_start" type="date" style="width: 50%;" :disabled="!data.edit"/>
					<InputText v-model="data.date_end" type="date" style="width: 50%;" :disabled="!data.edit"/>
				</template>
			</Column>
			<Column field="date_review" :header="$t('Review')" headerStyle="width: 100px">
				<template #body="{data}">
					{{ data['date_review_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.edit && data.date_review==null"/>
				</template>
				<template #editor="{ data }">
					<div  v-if="data.edit && data.date_review==null">
						<InputSwitch v-model="data.review" />
					</div>
					<div  v-else>
						{{ data['date_review_'+i18n.locale()] }}
					</div>
				</template>
			</Column>
			<Column field="date_approved" :header="$t('Approved')" headerStyle="width: 100px">
				<template #body="{data}">
					{{ data['date_approved_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.edit && data.date_approved==null && data.date_review!=null"/>
				</template>
				<template #editor="{ data }">
					<div  v-if="data.edit && data.date_approved==null && data.date_review!=null">
						<InputSwitch v-model="data.approved" />
					</div>
					<div  v-else>
						{{ data['date_approved_'+i18n.locale()] }}
					</div>
				</template>
			</Column>
			<template #expansion="{data}">
				<Basic :data="data" :step="1"/>
			</template>	
		</DataTable>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Basic: defineAsyncComponent(() => import('./Basic.vue')),
        },
		props: {
		},
		data() {
			return {
				analy: true,
				i18n: null,
				url: null,
				img: null,
				porc: 0,
				heigt_sc: 0,
				editingRows: [],
				expandedRows: [],
				editing: [],
				product: null,
				error:null,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.url = process.env.VUE_APP_API;
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			if(this.$store.state.screen<=728){
				this.heigt_sc =  450;
			} else {
				this.heigt_sc =  600;
			}
		},
		mounted() {
			
		},
		methods: {
			Init(event) {
				let clasif = this.$store.state.planning.clasification;
				let status = this.$store.state.planning.status;
				let impact = this.$store.state.planning.impact;
				let urengence = this.$store.state.planning.urengence;
				let { data, index, field } = event;
				this.$store.state.planning.editing.push(field+index);
				switch (field) {
					case 'cause_clasif':
						this.$store.state.planning.detail[index][field] = clasif.filter(val => val.id == data.cause_clasif)[0];
						break;
					case 'status':
						this.$store.state.planning.detail[index][field] = status.filter(val => val.id == data.status)[0];
						break;
					case 'impact':
						this.$store.state.planning.detail[index][field] = impact.filter(val => val.id == data.impact)[0];
						this.$store.state.planning.detail[index]['urengence'] = urengence.filter(val => val.id == data.urengence)[0];
						break;
				}
			},
			Save(event) {
				let { data, index, field } = event;
				this.$store.state.planning.editing = this.$store.state.planning.editing.filter(val => val != field+index);
				switch (field) {
					case 'cause_clasif':
						this.$store.state.planning.detail[index].cause_clasif_en = data.cause_clasif.en;
						this.$store.state.planning.detail[index].cause_clasif_es = data.cause_clasif.es;
						this.$store.state.planning.detail[index].cause_clasif = data.cause_clasif.id;
						break;
					case 'status':
						this.$store.state.planning.detail[index].status_en = data.status.en;
						this.$store.state.planning.detail[index].status_es = data.status.es;
						this.$store.state.planning.detail[index].status = data.status.id;
						break;
					case 'impact':
						this.$store.state.planning.detail[index].impact_en = data.impact.en;
						this.$store.state.planning.detail[index].impact_es = data.impact.es;
						this.$store.state.planning.detail[index].impact = data.impact.id;
						this.$store.state.planning.detail[index].urengence_en = data.urengence.en;
						this.$store.state.planning.detail[index].urengence_es = data.urengence.es;
						this.$store.state.planning.detail[index].urengence = data.urengence.id;
						break;
					case 'date_start':
						this.$store.state.planning.detail[index].date_start_en = this.ConvDate(data.date_start);
						this.$store.state.planning.detail[index].date_start_es = this.ConvDate(data.date_start);
						break;
					case 'date_end':
						this.$store.state.planning.detail[index].date_end_en = this.ConvDate(data.date_end);
						this.$store.state.planning.detail[index].date_end_es = this.ConvDate(data.date_end);
						break;
				}
			},
			ViewPDF(document){
				window.open(this.url+'/upload/'+document, '_blank');
			},
			ConvDate(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},

		}
	}
</script>
<style scoped lang="scss">
.edit_tab{
	padding: 10px 10px 10px 10px;
	font-size: 10px;
	color: red;
}
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

@media (max-width: 640px) {

	
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}


</style>